.primary-nav {
	position: absolute;
	right: 8.8vw;
	cursor: pointer;
	top: 8.3vh;
}

.primary-nav li {
	display: inline;
	margin: 0 1.7vw;
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: all 0.6s cubic-bezier(0.42, 0.4, 0.26, 1.01);
	-o-transition: all 0.6s cubic-bezier(0.42, 0.4, 0.26, 1.01);
	transition: all 0.6s cubic-bezier(0.42, 0.4, 0.26, 1.01);
	-webkit-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
	opacity: 0;
	visibility: hidden;
}

.primary-nav li.menu-in {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	opacity: 1;
	visibility: visible;
}

.primary-nav a {
	color: #ededed;
	font: 600 14px/120% 'MontSerrat', sans-serif;
	text-transform: lowercase;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}

/* .primary-nav a:hover {
    color: #fff;
} */

.primary-nav a::before {
	content: attr(data-hover);
	position: absolute;
	left: 0;
	width: 0;
	overflow: hidden;
	color: red;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.primary-nav li:hover a::before {
	width: 100%;
}
